'use client';

import { deleteCookie, setCookie } from 'cookies-next'
import axiosInterceptorInstance from '../axiosInterceptorInstance';
import axios from 'axios';
import { mutate } from 'swr';
import { openModal } from '@/components/providers/ModalProvider';
import { getDictionaryByCurrentLocale } from '@/components/providers/TranslationsProvider';
import { getRouter } from '@/pages/_app';

const generateToken = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}


export const loginFetch = async (email: string, password: string) => {
    let config = {
        headers: {
            DaisySession: '',
            Referer: 'https://local.elerama/',
        }
    }
    const authUrl: string = (
        (process.env.NEXT_PUBLIC_AUTH_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL ?? 'https://local.eleapi/b2b') +
        (process.env.NEXT_PUBLIC_AUTH_POST_USER_LOGIN ?? '/b2b_auth/login')
    );
    const dictionary: any = getDictionaryByCurrentLocale();
    try {
        const response: any = await axios.post(authUrl, { username: email, password: password });
        //if (response.status === 200) {}
        if (response?.error) {
            openModal(dictionary?.onboarding?.dialog?.loginFetchError?.title, dictionary?.onboarding?.dialog?.loginFetchError?.message);
            return null;
        }
        return response;
    } catch (error) {
        console.log(error);
        openModal(dictionary?.onboarding?.dialog?.loginFetchError?.title, dictionary?.onboarding?.dialog?.loginFetchError?.message);
    }
}

export const signOut = async () => {
    const router = getRouter();

    const logoutResponse = await logoutFetch();

    deleteCookie('TOKEN');
    deleteCookie('CURRENT_USER');
    if (typeof window !== 'undefined') {
        localStorage.removeItem('CURRENT_USER');
        localStorage.removeItem('CATALOGUE_TREE_DATA');
    }
    /* if (logoutResponse?.status === 200) {
        router.push('/onboarding/login/');
    } */
    router.push('/onboarding/login/');
}

export const logoutFetch = async () => {
    const authUrl: string = (
        (process.env.NEXT_PUBLIC_AUTH_BASE_URL ?? process.env.NEXT_PUBLIC_API_BASE_URL ?? 'https://local.eleapi/b2b') +
        (process.env.NEXT_PUBLIC_AUTH_POST_USER_LOGIN ?? '/b2b_auth/login')
    );

    try {
        const response = await axios.delete(authUrl);
        //if (response.status === 200) {}
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const logoutFetchDemo = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            deleteCookie('TOKEN');
            deleteCookie('CURRENT_USER');
            if (typeof window !== 'undefined') {
                localStorage.removeItem('CURRENT_USER');
            }
            resolve({ ok: true });
            //reload page
            window.location.reload();
        }, 2000)
    })
}

export const loginFetchDemo: any = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (email == 'test@test.com' && password == 'test') {
                setCookie('TOKEN', generateToken());
                resolve({ ok: true });
            } else {
                resolve({ ok: false });
            }
        }, 2000)
    })
}

export const getUser = async () => {

    try {
        const response = await axiosInterceptorInstance.get('/'); // Replace with your API endpoint

        // Handle the response data here
        //console.log(response.data);
        response.data = {
            "id": "PROFILE-7839",
            "name": "Reina Shannon",
            "email": "example@test.com",
            "role": "admin",
            "label": "user",
            "priority": "high",
            "preferred": false,
            "personificated": false
        }
        return response;
    } catch (error) {
        // Handle the error here
        console.error(error);
        return error;
    }

    /* return new Promise((resolve, reject) => {
        axiosInterceptorInstance.get('/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }).then((data: any) => {
            resolve(data.json().results);
        })
    }) */
}

/* export default async function handler(
    req: NextApiRequest,
    res: NextApiResponse
) {
    try {
        const { email, password } = req.body
        await signIn('credentials', { email, password })

        res.status(200).json({ success: true })
    } catch (error) {
        if (error.type === 'CredentialsSignin') {
            res.status(401).json({ error: 'Invalid credentials.' })
        } else {
            res.status(500).json({ error: 'Something went wrong.' })
        }
    }
} */

export const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
        const response = await axiosInterceptorInstance.post('/refresh', { token: refreshToken });
        const newAccessToken = response.data.accessToken;
        localStorage.setItem('accessToken', newAccessToken);

        // Optional: Trigger revalidation of all SWR hooks using the accessToken
        mutate(() => true); // Revalidates all SWR hooks
    } catch (error) {
        console.error('Failed to refresh token:', error);
        window.location.href = '/login';
    }
};
